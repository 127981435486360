<template>
    <div style="height: 100%">
        <v-banner v-if="isSpaceAdmin && isDistributedInstance && currentSpaceType === spaceTypes.EDUCATION_SPACE && !isSpaceArchived" class="mb-5">
            <v-avatar slot="icon" color="white" size="30"><v-icon large color="info">info</v-icon></v-avatar>
            <span class="subtitle-1">
                You can recall files and apps distributed to all students or if you wish to make updates, then return to the Master instance and distribute the
                changed materials.
            </span>
            <template v-slot:actions>
                <v-btn @click="goToMaster" target="_blank" color="primary">Return to Master instance</v-btn>
            </template>
        </v-banner>
        <v-alert v-if="isSpaceArchived && isSpaceAdmin && !isDistributedInstance && !isSpaceInAnyRestState" text color="info" class="rounded-0 mb-0">
            <v-row align="center">
                <v-col class="shrink"><v-icon color="info" right>info</v-icon></v-col>
                <v-col class="grow">
                    This space is archived. You may
                    <v-btn
                        text
                        small
                        color="primary"
                        :to="{
                            name: getCloneRoute,
                            oid: this.$route.oid,
                            query: {
                                sid: this.$route.params.sid
                            }
                        }">
                        create a new space
                    </v-btn>
                    reusing the contents of the Master instance, download files or distribute files/tables/applications to other spaces.
                </v-col>
                <v-col class="shrink">
                    <v-btn href="https://docs.nuvolos.cloud/user-guides/education-guides/archiving-your-course" target="_blank" color="primary">
                        Learn more
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-alert v-if="isSpaceRested" text color="info" class="rounded-0 mb-0">
            <v-row align="center">
                <v-col class="shrink"><v-icon color="info" right>mdi-sleep</v-icon></v-col>
                <v-col class="grow">The space is currently in resting state. Wake it up to work with your files.</v-col>
                <v-col class="shrink">
                    <v-btn outlined :href="restingDocLink" target="_blank" color="primary">
                        <v-icon left>{{ documentationIcon }}</v-icon>
                        documentation
                    </v-btn>
                </v-col>
                <v-col class="shrink">
                    <v-btn color="primary" @click="wakeSpace()">
                        <v-icon left>mdi-bell-outline</v-icon>
                        Wake up
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-alert v-if="isSpaceResting" text color="warning" class="rounded-0 mb-0">
            <v-row align="center">
                <v-col class="shrink"><v-icon color="warning" right>mdi-sleep</v-icon></v-col>
                <v-col class="grow">This space is being put to resting state.</v-col>
                <v-col class="shrink">
                    <v-btn outlined :href="restingDocLink" target="_blank" color="warning">
                        <v-icon left>{{ documentationIcon }}</v-icon>
                        documentation
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-alert v-if="isSpacePreResting" text color="info" class="rounded-0 mb-0">
            <v-row align="center">
                <v-col class="shrink"><v-icon color="info" right>mdi-bell-sleep-outline</v-icon></v-col>
                <v-col class="grow">This space will be put to resting state within 24 hours. Start an application to prevent putting to resting state.</v-col>
                <v-col class="shrink">
                    <v-btn outlined :href="restingDocLink" target="_blank" color="primary">
                        <v-icon left>{{ documentationIcon }}</v-icon>
                        documentation
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <div style="height: 100%">
            <TheSnapshotAppIframe />
            <TheSnapshotFileList :style="$route.name !== 'snapshot-files' ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' } : {}" />
            <v-container
                :style="
                    $route.name === 'snapshot-files' || $router.name === 'app-open'
                        ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' }
                        : {}
                "
                fluid>
                <router-view name="notifications"></router-view>
                <transition name="fadeonly" mode="out-in">
                    <keep-alive :include="['snapshot-tables']">
                        <router-view></router-view>
                    </keep-alive>
                </transition>
            </v-container>
        </div>
        <v-dialog v-model="wakingSpaceDialog" width="500" v-if="isSpaceWaking" persistent>
            <v-card>
                <v-card-title>
                    <v-icon left color="primary">mdi-bell-ring-outline</v-icon>
                    Space is currently waking up
                </v-card-title>
                <v-card-text class="pt-3">
                    <div>
                        The space is currently waking up. This can take a couple of minutes depending on the amount of data stored in files. You'll get a
                        notification when the space is completely awake.
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :to="{ name: 'home-dashboard', params: { oid: $route.params.oid } }" text>Return to Dashboard</v-btn>
                    <v-btn :href="restingDocLink" target="_blank" color="primary">
                        <v-icon left>{{ documentationIcon }}</v-icon>
                        documentation
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { breadcrumbs } from '@/mixins/breadcrumbs'
import { fetchTask } from '@/apis'

const TheSnapshotAppIframe = () => import('@/modules/snapshot/views/TheSnapshotAppIframe.vue')
const TheSnapshotFileList = () => import('@/modules/snapshot/views/TheSnapshotFileList.vue')

export default {
    mixins: [breadcrumbs, enumsData],
    components: {
        TheSnapshotAppIframe,
        TheSnapshotFileList
    },
    data() {
        return {
            restingDocLink: 'https://docs.nuvolos.cloud/administration/space-management/resting-spaces',
            documentationIcon: 'mdi-book-open-outline',
            wakingSpaceDialog: false
        }
    },
    computed: {
        ...mapState('snapshotStore', ['fileAreaType', 'pathArray', 'filesFetching']),
        ...mapState('spaceStore', ['spaceInstances']),
        ...mapGetters('spaceStore', [
            'currentSpaceType',
            'isSpaceAdmin',
            'isSpaceArchived',
            'isSpacePreResting',
            'isSpaceRested',
            'isSpaceWaking',
            'isSpaceResting',
            'isSpaceInAnyRestState'
        ]),
        ...mapGetters('snapshotStore', ['currentSnapshotData', 'currentFileLocalPath', 'isDevelopment']),
        ...mapGetters('instanceStore', ['isDistributedInstance']),
        currentSnapshotLockMode() {
            return this.currentSnapshotData ? this.currentSnapshotData.lock_mode_name : null
        },
        showSnapshotRestorationAlert() {
            if (
                ['snapshot-files', 'snapshot-tables', 'snapshot-applications'].includes(this.$route.name) &&
                this.currentSnapshotLockMode === 'RESTORING' &&
                this.isDevelopment
            ) {
                return true
            }
            return false
        },
        getCloneRoute() {
            return this.newSpace.ROUTES_BY_TYPE[this.currentSpaceType]
        },
        masterInstance() {
            const masterInstance = this.spaceInstances.find(instance => instance.long_id === this.instanceFixedNames.MASTER_INSTANCE)
            return masterInstance
        }
    },
    methods: {
        showSnapshotRestoreDialog() {
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            this.$store.dispatch('showGlobalDialog', {
                dialogTitle: 'Snapshot Restoration',
                dialogText: `A snapshot is being restored to this instance. You can work with your data once the restoration has been completed.<br><br> Task progress can be tracked <a style="text-decoration:none" href="${userTasksRoute}">here</a>.`,
                dialogAction: ['refreshInstanceSnapshots']
            })
        },
        goToMaster() {
            this.openInstance('master', this.masterInstance.iid, this.roleTypes.INSTANCE_EDITOR)
        },
        async wakeSpace() {
            try {
                const { data } = await this.$axios.post(`/spaces/${this.$route.params.sid}/wake`)
                this.$store.dispatch('spaceStore/fetchCurrentSpace')
                const taskResult = await fetchTask(data.tkid)
                if (taskResult instanceof Error) {
                    throw taskResult
                } else {
                    this.$store.dispatch('spaceStore/fetchCurrentSpace')
                }
            } catch (error) {
                this.$store.dispatch('spaceStore/fetchCurrentSpace')
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to wake up space.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
        }
    },
    mounted() {
        if (this.showSnapshotRestorationAlert) {
            this.showSnapshotRestoreDialog()
        }
    },
    watch: {
        isSpaceWaking: {
            handler(newVal) {
                this.wakingSpaceDialog = newVal
            },
            immediate: true
        },
        showSnapshotRestorationAlert: function (nextVal, preVal) {
            if (nextVal) {
                this.showSnapshotRestoreDialog()
            } else if (!nextVal && preVal) {
                this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                this.$store.dispatch('snapshotStore/fetchCurrentTables', this.$route.params.snid)
                this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
                this.$store.dispatch('hideGlobalDialog')
            }
        },
        $route: {
            handler(to, from) {
                if (to && to.name === 'snapshot-files' && this.$store.fileAreaType !== to.params.fileArea) {
                    this.$store.dispatch('snapshotStore/setFolderType', to.params.fileArea)
                }
                if (
                    from !== undefined &&
                    to.params.snid &&
                    from.params.snid &&
                    from.name === 'snapshot-files' &&
                    to.name === 'snapshot-files' &&
                    from.params.localPath !== to.params.localPath &&
                    to.params.localPath !== undefined &&
                    to.params.localPath !== this.currentFileLocalPath
                ) {
                    this.$store.dispatch('snapshotStore/setPathArray', to.params.localPath.split('/'))
                }
                if (
                    from !== undefined &&
                    to.params.snid &&
                    from.params.snid &&
                    from.name === 'snapshot-files' &&
                    to.name === 'snapshot-files' &&
                    from.params.localPath !== to.params.localPath &&
                    to.params.localPath === undefined &&
                    to.params.fileArea === from.params.fileArea
                ) {
                    this.$store.dispatch('snapshotStore/setPathArray', [''])
                }
                if (
                    (to.params.snid !== undefined &&
                        from !== undefined &&
                        from.params.snid !== undefined &&
                        to.params.snid.toString() !== from.params.snid.toString()) ||
                    (to.params.snid === undefined && from !== undefined && from.params.snid !== undefined)
                ) {
                    this.$store.dispatch('snapshotStore/resetSnapshotState')
                }
                if (
                    !['handin', 'handback'].includes(to.name) &&
                    ((to.params.snid !== undefined && from === undefined) ||
                        (to.params.snid !== undefined && from !== undefined && to.params.snid.toString() !== from.params.snid.toString()))
                ) {
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: true })
                    this.$store.dispatch('snapshotStore/fetchCurrentTables', to.params.snid)
                    this.$store.dispatch('snapshotStore/fetchApplications', to.params.snid)
                    if (window.sessionStorage.getItem('stagedObjects')) {
                        this.$store.dispatch('snapshotStore/setStagedObjectsFromStore', to.params.snid.toString())
                    }
                }
                // Handle file path
                if (to.name === 'snapshot-files' && to.params.localPath !== undefined) {
                    if (!this.filesFetching) {
                        this.$store.dispatch('snapshotStore/setPathArray', to.params.localPath.split('/'))
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: true })
                    }
                } else if (to.name === 'snapshot-files' && to.params.localPath === undefined) {
                    this.$store.dispatch('snapshotStore/setPathArray', [''])
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: true })
                }
                if (from && from.params.aid && to.params.aid === undefined && to.params.snid) {
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: false })
                }
            },
            immediate: true
        },
        currentFileLocalPath: function (nextVal, preVal) {
            if (nextVal !== preVal && this.$route.params.localPath !== nextVal) {
                switch (this.$route.name) {
                    case 'handin':
                        this.$router.push({
                            name: 'handin',
                            params: {
                                hid: this.$route.params.hid,
                                localPath: nextVal
                            }
                        })
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                            id: this.$route.params.hid,
                            route: this.$route,
                            setFetchingStatus: true
                        })
                        break
                    case 'handback':
                        this.$router.push({
                            name: 'handback',
                            params: {
                                hid: this.$route.params.hid,
                                localPath: nextVal
                            }
                        })
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                            id: this.$route.params.hid,
                            route: this.$route,
                            setFetchingStatus: true
                        })
                        break
                    default:
                        this.$router.push({
                            name: 'snapshot-files',
                            params: {
                                oid: this.$route.params.oid,
                                sid: this.$route.params.sid,
                                iid: this.$route.params.iid,
                                snid: this.$route.params.snid,
                                localPath: nextVal,
                                fileArea: this.fileAreaType
                            }
                        })
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                        break
                }
            }
        }
    }
}
</script>
